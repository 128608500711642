<template>
  <f7-page>
    <f7-navbar
      title="信秒贷Q&A"
      back-link="  "
      sliding
    >
      <f7-nav-right>
        <f7-link
          icon="icon-bars"
          open-panel="left"
        ></f7-link>
      </f7-nav-right>
    </f7-navbar>

    <f7-list
      accordion-list
      inset
    >

      <f7-list-item
        accordion-item
        title="Q1：申请人年龄要求？"
      >
        <f7-accordion-content>
          <f7-block>
            <f7-row no-gap>
              <f7-col width="10" style="font-size: 18px;">A：</f7-col>
              <f7-col width="90" style="line-height: 1.5;">申请人年龄在20至60周岁之间。</f7-col>
            </f7-row>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item
        accordion-item
        title="Q2：申请人开户要求？"
      >
        <f7-accordion-content>
          <f7-block>
            <f7-row no-gap>
              <f7-col width="10" style="font-size: 18px;">A：</f7-col>
              <f7-col width="90" style="line-height: 1.5;">客户开户证件必须为身份证。</f7-col>
            </f7-row>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item
        accordion-item
        title="Q3：额度签约有效期？"
      >
        <f7-accordion-content>
          <f7-block>
            <f7-row no-gap>
              <f7-col width="10" style="font-size: 18px;">A：</f7-col>
              <f7-col width="90" style="line-height: 1.5;">30天。信秒贷审批后，您需要在30天（从您获批时点开始，向后推30天的相同时点，并非24点）内签约协议，过期需要重新发起申请。</f7-col>
            </f7-row>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item
        accordion-item
        title="Q4：还款方式？"
      >
        <f7-accordion-content>
          <f7-block>
            <f7-row no-gap>
              <f7-col width="10" style="font-size: 18px;">A：</f7-col>
              <f7-col width="90" style="line-height: 1.5;">12个月及以下贷款：支持①按月付息，到期还本②等额本息③等额本金; 12个月以上贷款：支持①按月付息，按年还本② 等额本息③等额本金</f7-col>
            </f7-row>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item
        accordion-item
        title="Q5：是否可以延期/展期还款？"
      >
        <f7-accordion-content>
          <f7-block>
            <f7-row no-gap>
              <f7-col width="10" style="font-size: 18px;">A：</f7-col>
              <f7-col width="90" style="line-height: 1.5;">否，信秒贷不可以申请延期／展期还款。</f7-col>
            </f7-row>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>

      <f7-list-item
        accordion-item
        title="Q6：关于提款账号和还款账号。"
      >
        <f7-accordion-content>
          <f7-block>
            <f7-row no-gap>
              <f7-col width="10" style="font-size: 18px;">A：</f7-col>
              <f7-col width="90" style="line-height: 1.5;">可以通过手机银行或 ukey版个人网银变更还款账号，但无法修改提款账户，如您已签约并有后续的提款需求，建议不要注销提款账户。如提款账户已注销／挂失补卡，可重新发起额度申请及签约。</f7-col>
            </f7-row>
          </f7-block>
        </f7-accordion-content>
      </f7-list-item>

    </f7-list>

  </f7-page>
</template>

<style lang="less" scoped>
.row {
  padding: 10px;
}
</style>